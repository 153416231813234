import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'salesinvoice',
    loadChildren: () => import('./salesinvoice/salesinvoice.module').then( m => m.SalesinvoicePageModule)
  },
  {
    path: 'receiptvoucher',
    loadChildren: () => import('./receiptvoucher/receiptvoucher.module').then( m => m.ReceiptvoucherPageModule)
  },
  {
    path: 'customermaster',
    loadChildren: () => import('./customermaster/customermaster.module').then( m => m.CustomermasterPageModule)
  },
  {
    path: 'inchargemaster',
    loadChildren: () => import('./inchargemaster/inchargemaster.module').then( m => m.InchargemasterPageModule)
  },
  {
    path: 'salesinvoicelist',
    loadChildren: () => import('./salesinvoicelist/salesinvoicelist.module').then( m => m.SalesinvoicelistPageModule)
  },  
  {
    path: 'colllist',
    loadChildren: () => import('./colllist/colllist.module').then( m => m.ColllistPageModule)
  },
  {
    path: 'datepicker',
    loadChildren: () => import('./datepicker/datepicker.module').then( m => m.DatepickerPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
